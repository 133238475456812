class Navbar {
  constructor() {
    this._isToggleOn = false;
    let _self = this;

    $("[data-navbar-toggle]").click(function() {
      _self.toggleMainNavbar();
    });
  }

  toggleMainNavbar() {
    if(this._isToggleOn) {
      $(".navbar-toggler").removeClass("navbar-dark");
      $(".navbar-toggler").addClass("navbar-light");
    } else {
      $(".navbar-toggler").removeClass("navbar-light");
      $(".navbar-toggler").addClass("navbar-dark");
    }
    $(".SNavbar__options").toggleClass("SNavbar__options--expanded");
    this._isToggleOn = !this._isToggleOn;
  }
}

$(function(){
  var navbarHandler = new Navbar();
});